var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[(_vm.loading)?_c('v-row',[_c('v-col',[_c('h3',{staticClass:"text-center"},[_c('v-progress-circular',{staticClass:"ml-3",attrs:{"indeterminate":"","color":"primary"}}),_vm._v(" Please be patient, this may take a while. ")],1)])],1):_vm._e(),_c('v-alert',{staticStyle:{"z-index":"2","position":"fixed","right":"15px"},attrs:{"transition":"fade-transition","color":"error"},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" "),_c('v-icon',{staticClass:"ml-5",on:{"click":function($event){_vm.error = false}}},[_vm._v("mdi-close")])],1),_c('v-alert',{staticStyle:{"z-index":"2","position":"fixed","right":"15px"},attrs:{"transition":"fade-transition","color":"success"},model:{value:(_vm.submitted),callback:function ($$v) {_vm.submitted=$$v},expression:"submitted"}},[_vm._v(" Successfully Submitted "),_c('v-icon',{staticClass:"ml-5",on:{"click":function($event){_vm.error = false}}},[_vm._v("mdi-close")])],1),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.submitting),expression:"submitting"}],staticStyle:{"z-index":"2","position":"fixed","right":"15px","min-width":"300px"},attrs:{"transition":"fade-transition","color":"primary"}},[_c('v-progress-circular',{staticClass:"ml-3",attrs:{"indeterminate":"","color":"white"}}),_vm._v(" Submitting property... ")],1),(!_vm.loading)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"xs":"12","xl":"8"}},[_c('div',[(
            _vm.viewed_as === 'create_from_customer' || _vm.viewed_as === 'create'
          )?_c('h1',[_vm._v(" Create Property ")]):_vm._e(),(
            _vm.viewed_as === 'update_from_customer' || _vm.viewed_as === 'update'
          )?_c('h1',[_vm._v(" Update Property ")]):_vm._e(),(
            _vm.viewed_as === 'create_from_customer' || _vm.viewed_as === 'create'
          )?_c('p',{staticClass:"grey--text"},[_vm._v(" Please fill out the following details about the property. Ensure accuracy in your responses, as this information will assist us in understanding the property's characteristics and value. Thank you for your cooperation. ")]):_vm._e(),(
            _vm.viewed_as === 'update_from_customer' || _vm.viewed_as === 'update'
          )?_c('p',{staticClass:"grey--text"},[_vm._v(" To ensure that our records remain accurate and up-to-date, please provide the following details regarding the recent updates to the property. Your cooperation is greatly valued, as this information helps us maintain a comprehensive understanding of your property's evolving characteristics and value. ")]):_vm._e()]),_c('Add_Propertty',{attrs:{"viewed_as":_vm.viewed_as},on:{"handle_submit":_vm.handle_submit,"handle_cancel":_vm.handle_cancel}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }