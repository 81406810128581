<template>
  <v-container>
    <v-row v-if="loading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Please be patient, this may take a while.
        </h3>
      </v-col>
    </v-row>

    <v-alert
      v-model="error"
      transition="fade-transition"
      color="error"
      style="z-index: 2; position: fixed; right: 15px"
    >
      {{ errorMessage }}
      <v-icon class="ml-5" @click="error = false">mdi-close</v-icon>
    </v-alert>

    <v-alert
      v-model="submitted"
      transition="fade-transition"
      color="success"
      style="z-index: 2; position: fixed; right: 15px"
    >
      Successfully Submitted
      <v-icon class="ml-5" @click="error = false">mdi-close</v-icon>
    </v-alert>

    <v-alert
      v-show="submitting"
      transition="fade-transition"
      color="primary"
      style="z-index: 2; position: fixed; right: 15px; min-width: 300px"
    >
      <v-progress-circular indeterminate class="ml-3" color="white" />
      Submitting property...
    </v-alert>

    <v-row v-if="!loading" justify="center">
      <v-col xs="12" xl="8">
        <div>
          <h1
            v-if="
              viewed_as === 'create_from_customer' || viewed_as === 'create'
            "
          >
            Create Property
          </h1>
          <h1
            v-if="
              viewed_as === 'update_from_customer' || viewed_as === 'update'
            "
          >
            Update Property
          </h1>

          <p
            class="grey--text"
            v-if="
              viewed_as === 'create_from_customer' || viewed_as === 'create'
            "
          >
            Please fill out the following details about the property. Ensure
            accuracy in your responses, as this information will assist us in
            understanding the property's characteristics and value. Thank you
            for your cooperation.
          </p>

          <p
            class="grey--text"
            v-if="
              viewed_as === 'update_from_customer' || viewed_as === 'update'
            "
          >
            To ensure that our records remain accurate and up-to-date, please
            provide the following details regarding the recent updates to the
            property. Your cooperation is greatly valued, as this information
            helps us maintain a comprehensive understanding of your property's
            evolving characteristics and value.
          </p>
        </div>

        <Add_Propertty
          :viewed_as="viewed_as"
          @handle_submit="handle_submit"
          @handle_cancel="handle_cancel"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";
import Add_Propertty from "@/components/property/Add_Property.vue";

export default {
  props: {
    viewed_as: {
      type: String,
      required: true,
    },
  },

  components: {
    Add_Propertty,
  },

  data: () => ({
    loading: false,
    error: false,
    submitted: false,
    submitting: false,
    errorMessage: "",

    new_prop: {},
  }),

  methods: {
    handle_submit(prop) {
      console.log("This should be sending to the backend: ");
      console.log(prop);

      if (
        this.viewed_as === "create_from_customer" ||
        this.viewed_as === "create"
      ) {
        this.handle_create_customer_property(prop);
      }

      if (
        this.viewed_as === "update_from_customer" ||
        this.viewed_as === "update_from_deal" ||
        this.viewed_as === "update"
      ) {
        this.handle_update_customer_property(prop);
      }
    },

    async handle_create_customer_property(prop) {
      try {
        this.submitting = true;

        const response = await API().post(
          `api/internal-admin/property/create_customer_property`,
          {
            user_id: this.$store.getters["Auth/getAuthUser"].id,
            prop: prop,
            customer_id: this.$route.query.customer_id
              ? Number(this.$route.query.customer_id)
              : null,
          }
        );

        console.log(response);

        if (response.status === 201) {
          this.submitting = false;
          this.submitted = true;

          setTimeout(() => {
            if (this.viewed_as === "create_from_customer") {
              this.$router.push(
                `/dashboard/admin/customer/read/${this.$route.query.customer_id}`
              );
            }

            if (this.viewed_as === "create") {
              this.$router.push(`/dashboard/admin/list-all-properties`);
            }
          }, 3000);
        }
      } catch (error) {
        console.log("Error Message:", error.response.data.message);
        this.error = true;
        this.errorMessage = "Error Message: " + error.response.data.message;
        this.submitting = false;
      }
    },

    async handle_update_customer_property(prop) {
      try {
        this.submitting = true;
        const response = await API().patch(
          `api/internal-admin/property/update_customer_property`,
          {
            user_id: this.$store.getters["Auth/getAuthUser"].id,
            prop: prop,
          }
        );

        console.log(response);

        if (response.status === 201) {
          this.submitting = false;
          this.submitted = true;

          setTimeout(() => {
            if (this.viewed_as === "update_from_customer") {
              this.$router.push(
                `/dashboard/admin/customer/read/${this.$route.query.customer_id}`
              );
            }

            if (this.viewed_as === "update_from_deal") {
              this.$router.push(
                `/dashboard/admin/deal/read/${this.$route.query.deal_id}`
              );
            }

            if (this.viewed_as === "update") {
              this.$router.push(
                `/dashboard/admin/read-property/${this.$route.query.property_id}`
              );
            }
          }, 3000);
        }
      } catch (error) {
        console.log("Error Message:", error.response.data.message);
        this.error = true;
        this.errorMessage = "Error Message: " + error.response.data.message;
        this.submitting = false;
      }
    },

    handle_cancel() {
      if (
        this.viewed_as === "create_from_customer" ||
        this.viewed_as === "update_from_customer"
      ) {
        this.$router.push(
          `/dashboard/admin/customer/read/${this.$route.query.customer_id}`
        );
      }

      if (this.viewed_as === "create") {
        this.$router.push(`/dashboard/admin/list-all-properties`);
      }

      if (this.viewed_as === "update") {
        this.$router.push(
          `/dashboard/admin/read-property/${this.$route.query.property_id}`
        );
      }

      if (this.viewed_as === "update_from_deal") {
        this.$router.push(
          `/dashboard/admin/deal/read/${this.$route.query.deal_id}`
        );
      }
    },
  },
  mounted() {
    // console.log(this.viewed_as);
  },
};
</script>
