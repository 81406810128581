<template>
  <div class="d-flex justify-center">
    <!-- Side Navigation -->
    <div class="mr-2">
      <div class="side-nav">
        SECTIONS:
        <v-list dense>
          <!-- Navigation Links -->
          <v-list-item
            @click="
              if (!panels.includes(0)) panels.push(0);
              scrollToElement($refs.associated_customer.$el);
            "
          >
            <v-list-item-content>
              <v-list-item-title> Associate to Customers </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="
              if (!panels.includes(1)) panels.push(1);
              scrollToElement($refs.property_information.$el);
            "
          >
            <v-list-item-content>
              <v-list-item-title> Property Information </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="
              if (!panels.includes(2)) panels.push(2);
              scrollToElement($refs.rental_info.$el);
            "
            v-show="prop.prop_rent_info !== null && prop.rental_property"
          >
            <v-list-item-content>
              <v-list-item-title> Rental Information </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="
              if (!panels.includes(3)) panels.push(3);
              scrollToElement($refs.valuation.$el);
            "
          >
            <v-list-item-content>
              <v-list-item-title> Property Valuation </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="
              if (!panels.includes(4)) panels.push(4);
              scrollToElement($refs.existing_mortgage.$el);
            "
          >
            <v-list-item-content>
              <v-list-item-title> Existing Mortgage </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="
              if (!panels.includes(5)) panels.push(5);
              scrollToElement($refs.prop_cost.$el);
            "
          >
            <v-list-item-content>
              <v-list-item-title> Property Cost </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="
              if (!panels.includes(6)) panels.push(6);
              scrollToElement($refs.prop_style.$el);
            "
          >
            <v-list-item-content>
              <v-list-item-title> Property Style </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="
              panels = [0, 1, 2, 3, 4, 5, 6];
              scrollToElement($refs.associated_customer.$el);
            "
          >
            <v-list-item-content>
              <v-list-item-title class="green--text">
                Expand All
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="panels = []">
            <v-list-item-content>
              <v-list-item-title class="red--text">
                Collapse All
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </div>

    <div>
      <v-expansion-panels v-model="panels" multiple class="pa-2">
        <!-- property associate to customers -->
        <v-expansion-panel ref="associated_customer" class="bordered">
          <v-expansion-panel-header>
            Associate to Customers
          </v-expansion-panel-header>

          <v-divider />
          <v-expansion-panel-content>
            <div class="mt-5">
              <v-row>
                <v-col>
                  <v-alert
                    class="mt-5"
                    v-show="prop.customers.length > 0"
                    color="orange darken-3"
                    dense
                    transition="fade-transition"
                    outlined
                  >
                    <div class="d-flex align-center">
                      <v-icon color="orange darken-3"
                        >mdi-information-outline</v-icon
                      >
                      <span class="ml-2">
                        Any additional or changes in this associated customers
                        will be saved only after clicking the submit button.
                      </span>
                    </div>
                  </v-alert>

                  <v-data-table
                    v-if="prop.customers.length > 0"
                    :headers="customer_header"
                    item-key="order"
                    :items="prop.customers"
                    :items-per-page="5"
                    v-model="selected_customers"
                    show-select
                  />

                  <v-btn
                    v-if="selected_customers.length > 0"
                    @click="handle_unassociate_customer"
                    color="error"
                    class="mt-2"
                  >
                    unassociate
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-btn
                    class="my-5 ml-2"
                    v-if="!is_adding_customer"
                    @click="is_adding_customer = true"
                  >
                    associate customers
                  </v-btn>

                  <AddExistingCustomer
                    v-if="is_adding_customer"
                    type="associate_to_property"
                    :associated_customers="prop.customers"
                    :customer_suggestions="customer_suggestions"
                    :unassociated_customers="unassociated_customers"
                    @close="is_adding_customer = false"
                    @handle_save_customer="handle_save_customer"
                  />
                </v-col>
              </v-row>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- Property -->
        <v-expansion-panel ref="property_information" class="bordered">
          <v-expansion-panel-header>
            Property Information
          </v-expansion-panel-header>

          <v-divider />

          <v-expansion-panel-content>
            <v-form v-model="address_is_valid">
              <v-row class="mt-5">
                <v-col>
                  <p class="grey--text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
                    exercitationem fugit magnam repudiandae nesciunt animi
                    molestiae delectus atque pariatur deleniti, aspernatur sunt
                    soluta distinctio, ea ipsa fugiat earum iure possimus!
                  </p>
                </v-col>
              </v-row>

              <Places @placeChanged="place_changed" />

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="prop.address.line1"
                    label="Address Line 1"
                    hide-details="auto"
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="prop.address.line2"
                    label="Address Line 2"
                    hide-details="auto"
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3">
                  <v-text-field
                    v-model="prop.address.street_number"
                    label="Street Number"
                    hide-details="auto"
                    outlined
                  />
                </v-col>

                <v-col>
                  <v-text-field
                    v-model="prop.address.street_name"
                    label="Street Name"
                    hide-details="auto"
                    outlined
                  />
                </v-col>

                <v-col cols="4">
                  <v-select
                    v-model="prop.address.street_type"
                    :items="street_type_options"
                    label="Street Type"
                    hide-details="auto"
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-select
                    v-model="prop.address.street_direction"
                    :items="street_direction_options"
                    label="Street Direction"
                    hide-details="auto"
                    outlined
                  />
                </v-col>

                <v-col>
                  <v-text-field
                    v-model="prop.address.unit"
                    label="Unit"
                    hide-details="auto"
                    outlined
                  />
                </v-col>

                <v-col>
                  <v-text-field
                    v-model="prop.address.postal_code"
                    label="Postal Code"
                    hide-details="auto"
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="prop.address.city"
                    label="City"
                    hide-details="auto"
                    outlined
                  />
                </v-col>

                <v-col>
                  <v-select
                    v-model="prop.address.province"
                    :items="province_options"
                    label="Province"
                    hide-details="auto"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-btn v-if="!show_addresses" @click="show_addresses = true">
                    copy existing address
                  </v-btn>

                  <div v-if="show_addresses">
                    <v-divider class="my-5" />
                    <p class="text-body-1 grey--text">
                      Please select address that you want to copy:
                    </p>

                    <!-- add search -->
                    <v-text-field
                      v-model="search_address"
                      label="Search"
                      hide-details="auto"
                      append-icon="mdi-magnify"
                    />

                    <v-data-table
                      :search="search_address"
                      :headers="header_address"
                      item-key="order"
                      :items="associated_customer_addresses"
                      :items-per-page="5"
                      @click:row="handle_copy_address($event)"
                    />

                    <v-btn
                      class="mt-5"
                      color="red"
                      @click="show_addresses = false"
                    >
                      cancel
                    </v-btn>
                  </div>
                </v-col>
              </v-row>

              <v-divider class="my-10" />

              <v-row>
                <v-col>
                  <v-textarea
                    v-model="prop.address.legal_description"
                    label="Legal Description"
                    hide-details="auto"
                    auto-grow
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="prop.address.lot"
                    label="Lot"
                    hide-details="auto"
                    outlined
                  />
                </v-col>

                <v-col>
                  <v-text-field
                    v-model="prop.address.block"
                    label="Block"
                    hide-details="auto"
                    outlined
                  />
                </v-col>

                <v-col>
                  <v-text-field
                    v-model="prop.address.plan"
                    label="Plan"
                    hide-details="auto"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-form>

            <v-divider class="my-10" />

            <v-row>
              <v-col>
                <p class="grey--text">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
                  exercitationem fugit magnam repudiandae nesciunt animi
                  molestiae delectus atque pariatur deleniti, aspernatur sunt
                  soluta distinctio, ea ipsa fugiat earum iure possimus!
                </p>
              </v-col>
            </v-row>

            <v-form v-model="property_info_is_valid">
              <v-row class="mt-5">
                <v-col>
                  <v-select
                    v-model="prop.occupancy"
                    :items="occupancy_options"
                    label="Occupancy"
                    hide-details="auto"
                  />
                </v-col>

                <v-col> </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-textarea
                    v-model="prop.comments"
                    label="Comments"
                    auto-grow
                    hide-details="auto"
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="prop.purchase_price"
                    label="Purchase Price"
                    hide-details="auto"
                    outlined
                    @change="
                      prop.purchase_price = formatCurrency(prop.purchase_price)
                    "
                  />
                </v-col>

                <v-col>
                  <v-menu
                    v-model="purchase_date_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="prop.purchase_date"
                        label="Purchase Date"
                        outlined
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                        :rules="rules.date"
                        hint="YYYY-MM-DD format"
                        append-icon="mdi-calendar-blank"
                      />
                    </template>
                    <v-date-picker
                      no-title
                      v-model="prop.purchase_date"
                      @input="purchase_date_menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3">
                  <v-switch
                    v-model="prop.mls_listing"
                    label="MLS Listing"
                    dense
                  />
                </v-col>

                <v-col>
                  <v-text-field
                    v-if="prop.mls_listing"
                    v-model="prop.mls_listing_number"
                    label="MLS Listing Number"
                    hide-details="auto"
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row class="mt-5">
                <v-col cols="4">
                  <v-select
                    v-model="prop.tenure"
                    label="Select tenure"
                    :items="tenure_options"
                    hide-details="auto"
                  />
                </v-col>

                <v-col>
                  <v-select
                    :items="residential_status_options"
                    v-model="prop.residential_status"
                    label="Residential Status"
                    hide-details="auto"
                  />
                </v-col>

                <v-col>
                  <v-switch
                    v-model="prop.rental_property"
                    label="Rental Property"
                    @change="handle_rental_property_info"
                    dense
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- property rental info -->
        <v-expansion-panel
          ref="rental_info"
          class="bordered"
          v-show="prop.prop_rent_info !== null && prop.rental_property"
        >
          <v-expansion-panel-header>
            Rental Information
          </v-expansion-panel-header>

          <v-divider />

          <v-expansion-panel-content>
            <div
              v-if="prop.prop_rent_info !== null && prop.rental_property"
              class="mt-5"
            >
              <v-row>
                <v-col>
                  <p class="grey--text">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Id
                    distinctio incidunt modi consectetur dignissimos tempore
                    beatae. Fugit iusto quo architecto minus rem ratione
                    consequuntur quisquam hic corporis deleniti. Obcaecati,
                    consequuntur.
                  </p>
                </v-col>
              </v-row>

              <v-form v-model="rental_info_is_valid">
                <v-row>
                  <v-col>
                    <v-select
                      v-model="prop.prop_rent_info.rental_income_options"
                      :items="rental_income_options"
                      label="Rental Income Options"
                      hide-details="auto"
                    />
                  </v-col>

                  <v-col>
                    <v-text-field
                      v-model="prop.prop_rent_info.monthly_income"
                      label="Monthly Income"
                      hide-details="auto"
                      outlined
                      @change="
                        prop.prop_rent_info.monthly_income = formatCurrency(
                          prop.prop_rent_info.monthly_income
                        )
                      "
                    />
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- property valuation -->
        <v-expansion-panel ref="valuation" class="bordered">
          <v-expansion-panel-header>
            <div>Property Valuations</div>
          </v-expansion-panel-header>

          <v-divider />

          <v-expansion-panel-content>
            <div v-if="prop.prop_vals.length > 0">
              <v-alert
                class="mt-5"
                v-show="prop.customers.length > 0"
                color="orange darken-3"
                dense
                transition="fade-transition"
                outlined
              >
                <div class="d-flex align-center">
                  <v-icon color="orange darken-3"
                    >mdi-information-outline</v-icon
                  >
                  <span class="ml-2">
                    Any additional or changes in this property valuation will be
                    saved only after clicking the submit button.
                  </span>
                </div>
              </v-alert>
              <p class="warning--text mt-5">
                <b>DIRECTIONS</b> : Click the row to <b>EDIT</b> and use
                checkbox if you wish to <b>DELETE</b> one/multiple row.
              </p>

              <v-data-table
                :headers="property_valuation_header"
                item-key="order"
                :items="prop.prop_vals"
                :items-per-page="5"
                :rules="rules"
                @click:row="handle_updating_valuation"
                v-model="selected_valuations"
                show-select
              >
                <template v-slot:item.date="{ item }">
                  {{ item.date | formatDate }}
                </template>

                <template v-slot:item.value="{ item }">
                  {{ formatCurrency(item.value) }}
                </template>
              </v-data-table>

              <v-btn
                v-if="selected_valuations.length > 0"
                @click="handle_delete_valuation"
                color="error"
                class="mt-2"
              >
                delete
              </v-btn>
            </div>

            <AddValuation
              v-if="is_adding_valuation || is_updating_valuation"
              class="mt-5"
              :is_adding_valuation="is_adding_valuation"
              :is_updating_valuation="is_updating_valuation"
              :update_prop_val="new_prop_val"
              :prop_vals="prop.prop_vals"
              @handle_submit="handle_submit_valuation"
              @handle_cancel="
                is_adding_valuation = false;
                is_updating_valuation = false;
              "
            />

            <v-row class="mt-5">
              <v-col>
                <v-btn
                  v-if="!is_adding_valuation"
                  @click="is_adding_valuation = true"
                >
                  add valuation
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- property existing mortgage -->
        <v-expansion-panel ref="existing_mortgage" class="bordered">
          <v-expansion-panel-header>
            <div>Existing Mortgage</div>
          </v-expansion-panel-header>

          <v-divider />

          <v-expansion-panel-content>
            <div v-if="prop.prop_morts.length > 0">
              <v-alert
                class="mt-5"
                v-show="prop.prop_morts.length > 0"
                color="orange darken-3"
                dense
                transition="fade-transition"
                outlined
              >
                <div class="d-flex align-center">
                  <v-icon color="orange darken-3"
                    >mdi-information-outline</v-icon
                  >
                  <span class="ml-2">
                    Any additional or changes in this existing mortgage will be
                    saved only after clicking the submit button.
                  </span>
                </div>
              </v-alert>

              <p class="warning--text mt-5">
                <b>DIRECTIONS</b> : Click the row to <b>EDIT</b> and use
                checkbox if you wish to <b>DELETE</b> one/multiple row.
              </p>

              <v-data-table
                :headers="existing_mortgage_header"
                item-key="order"
                :items="prop.prop_morts"
                :items-per-page="5"
                @click:row="handle_to_update_mortgage($event)"
                v-model="selected_mortgages"
                show-select
              >
                <template v-slot:item.balance_remaining="{ item }">
                  {{ formatCurrency(item.balance_remaining) }}
                </template>

                <template v-slot:item.maturity_date="{ item }">
                  {{ item.maturity_date | formatDate }}
                </template>
              </v-data-table>

              <v-btn
                v-if="selected_mortgages.length > 0"
                @click="handle_delete_mortgage"
                color="error"
                class="mt-2"
              >
                delete
              </v-btn>
            </div>

            <AddExistingMort
              v-if="is_adding_mortgage || is_updating_mortgage"
              class="mt-5"
              :is_adding_mortgage="is_adding_mortgage"
              :is_updating_mortgage="is_updating_mortgage"
              :update_prop_mort="new_prop_mort"
              :prop_morts="prop.prop_morts"
              @handle_submit="
                is_adding_mortgage
                  ? handle_save_mortgage($event)
                  : handle_update_mortgage($event)
              "
              @handle_cancel="
                is_adding_mortgage = false;
                is_updating_mortgage = false;
              "
            />

            <v-row class="mt-5">
              <v-col>
                <v-btn
                  v-if="!is_adding_mortgage"
                  @click="is_adding_mortgage = true"
                >
                  add mortgage
                </v-btn>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- property cost -->
        <v-expansion-panel ref="prop_cost" class="bordered">
          <v-expansion-panel-header> Property Cost </v-expansion-panel-header>

          <v-divider />

          <v-expansion-panel-content>
            <v-form v-model="property_cost_is_valid">
              <!-- <v-row class="mt-5">
                <v-col>
                  <v-switch
                    label="Include in TDS"
                    v-model="prop.prop_cost.include_in_tds"
                    dense
                  />
                </v-col>
              </v-row>

              <v-divider class="mb-10" /> -->

              <v-row class="mt-5">
                <v-col>
                  <v-text-field
                    v-model="prop.prop_cost.property_taxes"
                    label="Property Taxes (Annual)"
                    outlined
                    hide-details="auto"
                    @change="
                      prop.prop_cost.property_taxes = formatCurrency(
                        prop.prop_cost.property_taxes
                      )
                    "
                  />
                </v-col>

                <v-col>
                  <v-text-field
                    v-model="prop.prop_cost.tax_year"
                    label="Tax Year"
                    :rules="[rules.number]"
                    outlined
                    hide-details="auto"
                    maxlength="4"
                  />
                </v-col>

                <v-col>
                  <v-select
                    v-model="prop.prop_cost.paid_by"
                    label="Paid By"
                    :items="paid_by_options"
                    outlined
                    hide-details="auto"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-switch
                    label="In Arrears"
                    v-model="prop.prop_cost.tax_arrears"
                    dense
                  />
                </v-col>
              </v-row>

              <v-row v-if="prop.prop_cost.tax_arrears">
                <v-col>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        v-model="prop.prop_cost.tax_arrears_amount"
                        label="Amount"
                        outlined
                        hide-details="auto"
                        @change="
                          prop.prop_cost.tax_arrears_amount = formatCurrency(
                            prop.prop_cost.tax_arrears_amount
                          )
                        "
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="prop.prop_cost.tax_arrears_notes.length > 0">
                    <v-col>
                      <v-alert
                        class="mt-5"
                        v-show="prop.prop_cost.tax_arrears_notes.length > 0"
                        color="orange darken-3"
                        dense
                        transition="fade-transition"
                        outlined
                      >
                        <div class="d-flex align-center">
                          <v-icon color="orange darken-3"
                            >mdi-information-outline</v-icon
                          >
                          <span class="ml-2">
                            Any additional or changes in this tax in arrears notes
                            will be saved only after clicking the submit button.
                          </span>
                        </div>
                      </v-alert>

                      <v-data-table
                        v-model="selected_tax_arrears_notes"
                        :headers="note_header"
                        item-key="order"
                        :items="prop.prop_cost.tax_arrears_notes"
                        :items-per-page="5"
                        @click:row="handle_updating_tax_arrears_note($event)"
                        show-select
                      />

                      <v-btn
                        v-if="selected_tax_arrears_notes.length > 0"
                        class="mt-2"
                        color="error"
                        @click="handle_delete_tax_arrears_note"
                      >
                        delete
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-btn
                        v-if="
                          !adding_tax_arrears_note && !updating_tax_arrears_note
                        "
                        @click="
                          adding_tax_arrears_note = true;
                          updating_tax_arrears_note = false;
                        "
                      >
                        add note
                      </v-btn>

                      <v-card
                        v-if="
                          adding_tax_arrears_note || updating_tax_arrears_note
                        "
                      >
                        <v-card-title>
                          {{ adding_tax_arrears_note ? "Adding" : "Updating" }}
                          Note
                        </v-card-title>

                        <v-card-text>
                          <v-textarea
                            v-model="new_tax_arrears_note.note"
                            label="Enter Note"
                            auto-grow
                            outlined
                            hide-details="auto"
                          />
                        </v-card-text>

                        <v-divider />

                        <v-card-actions>
                          <v-btn
                            @click="
                              adding_tax_arrears_note
                                ? handle_save_tax_arrears_note(
                                    new_tax_arrears_note
                                  )
                                : handle_update_tax_arrears_note(
                                    new_tax_arrears_note
                                  )
                            "
                            color="primary"
                            :disabled="!new_tax_arrears_note.note.length > 0"
                          >
                            save
                          </v-btn>

                          <v-btn
                            class="ml-2"
                            @click="
                              adding_tax_arrears_note = false;
                              updating_tax_arrears_note = false;
                            "
                          >
                            cancel
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-divider class="my-10" />

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="prop.prop_cost.maintenance_fee"
                    label="Maintenance/Condo Fee (Monthly)"
                    outlined
                    hide-details="auto"
                    @change="
                      prop.prop_cost.maintenance_fee = formatCurrency(
                        prop.prop_cost.maintenance_fee
                      )
                    "
                  />
                </v-col>

                <v-col>
                  <v-switch
                    label="In Arrears"
                    v-model="prop.prop_cost.maintenance_arrears"
                    dense
                  />
                </v-col>
              </v-row>

              <v-row v-if="prop.prop_cost.maintenance_arrears">
                <v-col>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        v-model="prop.prop_cost.maintenance_arrears_amount"
                        label="Amount"
                        outlined
                        hide-details="auto"
                        @change="
                          prop.prop_cost.maintenance_arrears_amount =
                            formatCurrency(
                              prop.prop_cost.maintenance_arrears_amount
                            )
                        "
                      />
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="prop.prop_cost.maintenance_arrears_notes.length > 0"
                  >
                    <v-col>
                      <v-alert
                        class="mt-5"
                        v-show="
                          prop.prop_cost.maintenance_arrears_notes.length > 0
                        "
                        color="orange darken-3"
                        dense
                        transition="fade-transition"
                        outlined
                      >
                        <div class="d-flex align-center">
                          <v-icon color="orange darken-3"
                            >mdi-information-outline</v-icon
                          >
                          <span class="ml-2">
                            Any additional or changes in this maintenance in arrears
                            notes will be saved only after clicking the submit
                            button.
                          </span>
                        </div>
                      </v-alert>

                      <v-data-table
                        v-model="selected_maintenance_arrears_notes"
                        :headers="note_header"
                        item-key="order"
                        :items="prop.prop_cost.maintenance_arrears_notes"
                        :items-per-page="5"
                        @click:row="
                          handle_updating_maintenance_arrears_note($event)
                        "
                        show-select
                      />

                      <v-btn
                        v-if="selected_maintenance_arrears_notes.length > 0"
                        class="mt-2"
                        color="error"
                        @click="handle_delete_maintenance_arrears_note"
                      >
                        delete
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-btn
                        v-if="
                          !adding_maintenance_arrears_note &&
                          !updating_maintenance_arrears_note
                        "
                        @click="
                          adding_maintenance_arrears_note = true;
                          updating_maintenance_arrears_note = false;
                        "
                      >
                        add note
                      </v-btn>

                      <v-card
                        v-if="
                          adding_maintenance_arrears_note ||
                          updating_maintenance_arrears_note
                        "
                      >
                        <v-card-title>
                          {{
                            adding_maintenance_arrears_note
                              ? "Adding"
                              : "Updating"
                          }}
                          Note
                        </v-card-title>

                        <v-card-text>
                          <v-textarea
                            v-model="new_maintenance_arrears_note.note"
                            label="Enter Note"
                            auto-grow
                            outlined
                            hide-details="auto"
                          />
                        </v-card-text>

                        <v-divider />

                        <v-card-actions>
                          <v-btn
                            @click="
                              adding_maintenance_arrears_note
                                ? handle_save_maintenance_arrears_note(
                                    new_maintenance_arrears_note
                                  )
                                : handle_update_maintenance_arrears_note(
                                    new_maintenance_arrears_note
                                  )
                            "
                            color="primary"
                            :disabled="
                              !new_maintenance_arrears_note.note.length > 0
                            "
                          >
                            save
                          </v-btn>

                          <v-btn
                            class="ml-2"
                            @click="
                              adding_maintenance_arrears_note = false;
                              updating_maintenance_arrears_note = false;
                            "
                          >
                            cancel
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-divider class="my-10" />

              <v-row>
                <v-col cols="4">
                  <v-text-field
                    v-model="
                      prop.prop_cost.maintenance_condo_allocation_percentage
                    "
                    label="Maintenance/Condo fee allocation percentage"
                    type="number"
                    outlined
                    hide-details="auto"
                    :rules="[rules.number]"
                    @blur="
                      prop.prop_cost.maintenance_condo_allocation_percentage ===
                      ''
                        ? (prop.prop_cost.maintenance_condo_allocation_percentage =
                            null)
                        : (prop.prop_cost.maintenance_condo_allocation_percentage =
                            prop.prop_cost.maintenance_condo_allocation_percentage)
                    "
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="3">
                  <v-switch
                    label="Fee Include Heat"
                    v-model="prop.prop_cost.fee_include_heat"
                    dense
                    @change="prop.prop_cost.heating = 0"
                  />
                </v-col>

                <v-col>
                  <v-text-field
                    v-model="prop.prop_cost.heating"
                    label="Heating (Monthly)"
                    outlined
                    hide-details="auto"
                    :disabled="prop.prop_cost.fee_include_heat"
                    @change="
                      prop.prop_cost.heating = formatCurrency(
                        prop.prop_cost.heating
                      )
                    "
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="prop.prop_cost.others"
                    label="Others (Monthly)"
                    outlined
                    hide-details="auto"
                    @change="
                      prop.prop_cost.others = formatCurrency(
                        prop.prop_cost.others
                      )
                    "
                  />
                </v-col>

                <v-col>
                  <v-text-field
                    v-model="prop.prop_cost.hydro"
                    label="Hydro/Electricty (Monthly)"
                    outlined
                    hide-details="auto"
                    @change="
                      prop.prop_cost.hydro = formatCurrency(
                        prop.prop_cost.hydro
                      )
                    "
                  />
                </v-col>

                <v-col>
                  <v-text-field
                    v-model="prop.prop_cost.insurance"
                    label="Insurance (Monthly)"
                    outlined
                    hide-details="auto"
                    @change="
                      prop.prop_cost.insurance = formatCurrency(
                        prop.prop_cost.insurance
                      )
                    "
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="prop.prop_cost.management"
                    label="Management (Monthly)"
                    outlined
                    hide-details="auto"
                    @change="
                      prop.prop_cost.management = formatCurrency(
                        prop.prop_cost.management
                      )
                    "
                  />
                </v-col>

                <v-col>
                  <v-text-field
                    v-model="prop.prop_cost.repairs"
                    label="Repairs (Monthly)"
                    outlined
                    hide-details="auto"
                    @change="
                      prop.prop_cost.repairs = formatCurrency(
                        prop.prop_cost.repairs
                      )
                    "
                  />
                </v-col>

                <v-col>
                  <v-text-field
                    v-model="prop.prop_cost.interest_fee"
                    label="Interest fee (Monthly)"
                    @change="
                      prop.prop_cost.interest_fee = formatCurrency(
                        prop.prop_cost.interest_fee
                      )
                    "
                    hide-details="auto"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <!-- property style -->
        <v-expansion-panel ref="prop_style" class="bordered">
          <v-expansion-panel-header> Property Style </v-expansion-panel-header>

          <v-divider />

          <v-expansion-panel-content>
            <v-form v-model="property_style_is_valid">
              <v-row class="mt-5">
                <v-col>
                  <p class="grey--text">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
                    exercitationem fugit magnam repudiandae nesciunt animi
                    molestiae delectus atque pariatur deleniti, aspernatur sunt
                    soluta distinctio, ea ipsa fugiat earum iure possimus!
                  </p>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-select
                    v-model="prop.prop_style.property_style"
                    label="Style"
                    :items="property_style_options"
                    hide-details="auto"
                  />
                </v-col>

                <v-col>
                  <v-select
                    v-model="prop.prop_style.property_type"
                    label="Type"
                    :items="property_type_options"
                    hide-details="auto"
                  />
                </v-col>

                <v-col>
                  <v-select
                    v-model="prop.prop_style.garage_size"
                    label="Garage Size"
                    :items="garage_size_options"
                    hide-details="auto"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-select
                    v-model="prop.prop_style.garage_type"
                    label="Garage Type"
                    :items="garage_type_options"
                    hide-details="auto"
                  />
                </v-col>

                <v-col>
                  <v-text-field
                    v-model="prop.prop_style.living_space"
                    label="living Space (sq ft)"
                    type="number"
                    outlined
                    hide-details="auto"
                    @blur="
                      prop.prop_style.living_space === ''
                        ? (prop.prop_style.living_space = null)
                        : (prop.prop_style.living_space =
                            prop.prop_style.living_space)
                    "
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-select
                    v-model="prop.prop_style.construction_type"
                    label="Construction Type"
                    :items="construction_type_options"
                    hide-details="auto"
                  />
                </v-col>

                <v-col>
                  <v-text-field
                    v-model="prop.prop_style.structure_age"
                    label="Structure Age"
                    type="number"
                    :rules="[rules.number]"
                    outlined
                    hide-details="auto"
                    @blur="
                      prop.prop_style.structure_age === ''
                        ? (prop.prop_style.structure_age = null)
                        : (prop.prop_style.structure_age =
                            prop.prop_style.structure_age)
                    "
                  />
                </v-col>

                <v-col>
                  <v-text-field
                    v-model="prop.prop_style.number_of_units"
                    label="Number Of Units"
                    type="number"
                    :rules="[rules.number]"
                    outlined
                    hide-details="auto"
                    @blur="
                      prop.prop_style.number_of_units === ''
                        ? (prop.prop_style.number_of_units = null)
                        : (prop.prop_style.number_of_units =
                            prop.prop_style.number_of_units)
                    "
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-text-field
                    v-model="prop.prop_style.lot_size"
                    label="Lot Size"
                    type="number"
                    :rules="[rules.number]"
                    outlined
                    hide-details="auto"
                    @blur="
                      prop.prop_style.lot_size === ''
                        ? (prop.prop_style.lot_size = null)
                        : (prop.prop_style.lot_size = prop.prop_style.lot_size)
                    "
                  />
                </v-col>

                <v-col>
                  <v-select
                    v-model="prop.prop_style.lot_measurement_type"
                    label="Lot Measurement Type"
                    :items="lot_measurement_type_options"
                    hide-details="auto"
                  />
                </v-col>

                <v-col>
                  <v-select
                    v-model="prop.prop_style.heat"
                    label="Heat Options"
                    :items="heat_options"
                    hide-details="auto"
                  />
                </v-col>

                <v-col>
                  <v-select
                    v-model="prop.prop_style.water_info"
                    label="Water Info"
                    :items="water_info_options"
                    hide-details="auto"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4">
                  <v-select
                    v-model="prop.prop_style.sewage_info"
                    label="Sewage Info"
                    :items="sewage_info_options"
                    hide-details="auto"
                  />
                </v-col>
              </v-row>

              <v-divider class="my-10" />

              <v-row>
                <v-col>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Consequatur sunt, culpa odio distinctio eius temporibus
                    minus rerum dolor molestias quia incidunt laboriosam ducimus
                    ullam dolore atque recusandae asperiores illo a.
                  </p>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-textarea
                    v-model="prop.prop_style.environmental_hazard_comments"
                    label="Comments about the property"
                    auto-grow
                    outlined
                    hide-details="auto"
                  />
                </v-col>
              </v-row>

              <v-divider class="mb-5" />

              <v-row>
                <v-col cols="4">
                  <v-switch
                    v-model="prop.prop_style.environmental_hazard"
                    label="Environmental Hazard"
                    dense
                  />
                </v-col>

                <v-col>
                  <v-textarea
                    v-if="prop.prop_style.environmental_hazard"
                    v-model="prop.prop_style.environmental_hazard_notes"
                    label="Add Notes"
                    auto-grow
                    outlined
                    hide-details="auto"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- actions -->
      <v-row class="mt-5">
        <v-col>
          <!-- Submit Button -->
          <v-btn @click="sendEvent" color="primary"> Submit </v-btn>

          <v-btn class="ml-5" @click="handle_cancel_btn"> Cancel </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import API from "@/plugins/API";
import AddValuation from "@/components/property/Add_Valuation.vue";
import AddExistingMort from "@/components/property/Add_Existing_Mort.vue";
import AddExistingCustomer from "@/components/customer/AddExistingCustomer.vue";
import Places from "@/components/google_maps/Places.vue";
import filt from "@/plugins/filters";

export default {
  name: "Add_Property",

  props: {
    viewed_as: {
      type: String,
      required: true,
    },

    existing_addresses: {
      type: Array,
    },

    property_data: {
      type: Object,
    },

    adding_property: {
      type: Boolean,
    },
  },

  components: {
    AddValuation,
    AddExistingMort,
    AddExistingCustomer,
    Places,
  },

  data: () => ({
    panels: [0],

    property_info_is_valid: false,
    rental_info_is_valid: false,
    property_style_is_valid: false,
    property_cost_is_valid: false,
    address_is_valid: false,

    purchase_date_menu: false,

    rules: {
      required: (value) => !!value || "Required.",

      number: (value) => {
        const val = parseFloat(value);
        return !isNaN(val) || "Must be a number.";
      },

      date: [
        (v) =>
          (v && /^\d{4}-\d{2}-\d{2}$/.test(v)) ||
          "Invalid date. Format should be YYYY-MM-DD",
      ],
    },

    show_addresses: false,
    search_address: "",

    selected_tax_arrears_notes: [],
    selected_maintenance_arrears_notes: [],

    // prop options
    tenure_options: ["freehold", "leasehold", "condo"],
    occupancy_options: [
      "Owner-Occupied",
      "Owner-Occupied & Rental",
      "Rental",
      "Second Home",
    ],
    residential_status_options: ["Residential", "Commercial"],

    // prop_rent_info options
    rental_income_options: [
      "Residential Rental Properties",
      "Commercial Rental Properties",
      "Vacation Rentals",
      "Land Leasing",
      "Storage Units",
      "Mobile Homes and RV Parks",
      "Single Room Rentals",
      "Co-living Spaces",
      "Renting Equipment",
      "Billboards and Advertising Space",
      "Intellectual Property",
      "Agricultural Land",
      "Parking Spaces",
      "Event Spaces",
      "Boat and Aircraft Rentals",
      "Time-Share Properties",
      "Co-working Spaces",
    ],

    // address options
    province_options: [
      "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Nova Scotia",
      "Northwest Territories",
      "Nunavut",
      "Ontario",
      "Prince Edward Island",
      "Quebec",
      "Saskatchewan",
      "Yukon",
    ],

    street_direction_options: ["N", "S", "E", "W", "NE", "NW", "SE", "SW"],

    street_type_options: [
      "Abbey",
      "Acres",
      "Allée",
      "Alley",
      "Autoroute",
      "Avenue",
      "Bay",
      "Beach",
      "Bend",
      "Boulevard",
      "By-Pass",
      "Byway",
      "Campus",
      "Cape",
      "Carré",
      "Carrefour",
      "Centre",
      "Cercle",
      "Chase",
      "Chemin",
      "Circle",
      "Circuit",
      "Close",
      "Common",
      "Concession",
      "Corners",
      "Côte",
      "Cour",
      "Cours",
      "Court",
      "Cove",
      "Crescent",
      "Croissant",
      "Crossing",
      "Cul-de-sac",
      "Dale",
      "Dell",
      "Diversion",
      "Downs",
      "Drive",
      "Éhangeur",
      "End",
      "Esplande",
      "Estates",
      "Expressway",
      "Extension",
      "Farm",
      "Field",
      "Forest",
      "Freeway",
      "Front",
      "Gardens",
      "Gate",
      "Glen",
      "Green",
      "Grounds",
      "Grove",
      "Harbour",
      "Heath",
      "Heights",
      "Highlands",
      "Highway",
      "Hill",
      "Hollow",
      "Île",
      "Impasse",
      "Inlet",
      "Island",
      "Key",
      "knoll",
      "Landing",
      "Lane",
      "Limits",
      "Line",
      "Link",
      "Lookout",
      "Loop",
      "Mall",
      "Manor",
      "Maze",
      "Meadow",
      "Mews",
      "Montée",
      "Moor",
      "Mount",
      "Mountain",
      "Orchard",
      "Parade",
      "Parc",
      "Park",
      "Parkway",
      "Passage",
      "Path",
      "Pathway",
      "Pines",
      "Place",
      "Plateau",
      "Plaza",
      "Point",
      "Pointe",
      "Port",
      "Private",
      "Promenade",
      "Quai",
      "Quay",
      "Ramp",
      "Rang",
      "Range",
      "Ridge",
      "Rise",
      "Road",
      "Rond-point",
      "Route",
      "Row",
      "Rue",
      "Ruelle",
      "Run",
      "Sentier",
      "Square",
      "Street",
      "Subdivision",
      "Terrance",
      "Terrasse",
      "Thicket",
      "Towers",
      "Townline",
      "Trail",
      "Turnabout",
      "Vale",
      "Via",
      "View",
      "Village",
      "Villas",
      "Vista",
      "Voie",
      "Walk",
      "Way",
      "Wharf",
      "Wood",
      "Wynd",
    ],

    // prop_style options
    property_style_options: [
      "one-storey",
      "two-storey",
      "bi-level",
      "split-level",
      "storey-and-a-half",
      "three-storey",
      "other",
    ],

    property_type_options: [
      "detached",
      "semi-detached",
      "row-housing",
      "duplex-detached",
      "apartment-low-rise",
      "apartment-high-rise",
      "mobile",
      "stacked",
    ],

    garage_size_options: ["single", "double", "triple", "none"],
    garage_type_options: ["attached", "detached", "underground"],
    construction_type_options: ["existing", "new"],
    lot_measurement_type_options: ["feet", "meters"],
    heat_options: ["gas", "electric", "oil", "wood", "other"],
    water_info_options: ["municipal", "well", "other"],
    sewage_info_options: ["municipal", "septic", "other"],
    rental_income_offset_options: [
      "None",
      "Add Percentage to Gross Income",
      "Reduce Rental exp., add bal to Gross Inc.",
    ],

    // prop_cost options
    paid_by_options: ["Borrower", "Lender"],

    prop: {
      occupancy: null,
      comments: null,
      purchase_price: 0,
      purchase_date: null,
      tenure: null,
      rental_property: false,
      mls_listing: false,
      mls_listing_number: null,
      residential_status: null,

      // prop_rent_info
      prop_rent_info: null,

      // customers
      customers: [],

      // address data
      address: {
        line1: null,
        line2: null,
        street_number: null,
        street_name: null,
        street_type: null,
        street_direction: null,
        unit: null,
        postal_code: null,
        city: null,
        province: null,
        years: 0,
        months: 0,

        legal_description: null,
        lot: null,
        block: null,
        plan: null,
      },

      // property cost data
      prop_cost: {
        include_in_tds: false,
        property_taxes: 0,
        tax_year: null,
        paid_by: null,
        tax_arrears: false,
        tax_arrears_amount: 0,
        tax_arrears_notes: [],
        maintenance_fee: 0,
        maintenance_arrears: false,
        maintenance_arrears_amount: 0,
        maintenance_arrears_notes: [],
        maintenance_condo_allocation_percentage: null,
        fee_include_heat: false,
        heating: 0,
        others: 0,
        hydro: 0,
        insurance: 0,
        management: 0,
        repairs: 0,
        interest_fee: 0,
      },

      // property style data
      prop_style: {
        property_style: null,
        property_type: null,
        garage_size: null,
        garage_type: null,
        living_space: null,
        construction_type: null,
        structure_age: null,
        number_of_units: null,
        lot_size: null,
        lot_measurement_type: null,
        heat: null,
        water_info: null,
        sewage_info: null,
        environmental_hazard: false,
        environmental_hazard_notes: null,
        environmental_hazard_comments: null,
      },

      // prop_val data
      prop_vals: [],

      // prop_mort data
      prop_morts: [],
    },

    // for adding new tax arrears note
    adding_tax_arrears_note: false,
    updating_tax_arrears_note: false,
    new_tax_arrears_note: { note: "" },

    // for adding new maintenance arrears note
    adding_maintenance_arrears_note: false,
    updating_maintenance_arrears_note: false,
    new_maintenance_arrears_note: { note: "" },

    // for adding new valuation
    is_adding_valuation: false,
    is_updating_valuation: false,
    new_prop_val: {},
    selected_valuations: [],

    property_valuation_header: [
      { text: "Type", value: "type" },
      { text: "Value", value: "value" },
      { text: "Source", value: "source" },
      { text: "Date", value: "date" },
      { text: "Final Value", value: "final_value" },
    ],

    // for adding new mortgage
    is_adding_mortgage: false,
    is_updating_mortgage: false,
    new_prop_mort: {},
    selected_mortgages: [],

    existing_mortgage_header: [
      { text: "Position", value: "position" },
      { text: "Lender", value: "lender" },
      { text: "Balance Remaining", value: "balance_remaining" },
      { text: "Maturity Date", value: "maturity_date" },
    ],

    // for adding existing customer
    is_adding_customer: false,
    is_updating_customer: false,
    new_customer: {},
    selected_customers: [],
    customer_suggestions: [],
    unassociated_customers: [],

    // for headers

    customer_header: [
      { text: "First Name", value: "first_name" },
      { text: "Middle Name", value: "middle_name" },
      { text: "Last Name", value: "last_name" },
      { text: "Email", value: "email" },
    ],

    note_header: [{ text: "Note", value: "note" }],

    header_address: [
      { text: "Customer Name", value: "customer_name" },

      {
        text: "Number",
        value: "street_number",
      },

      {
        text: "Name",
        value: "street_name",
      },

      {
        text: "Type",
        value: "street_type",
      },

      {
        text: "Direction",
        value: "street_direction",
      },

      {
        text: "Unit",
        value: "unit",
      },

      {
        text: "Postal Code",
        value: "postal_code",
      },

      {
        text: "City",
        value: "city",
      },

      {
        text: "Province",
        value: "province",
      },
    ],
  }),

  methods: {
    getOffsetTop(element) {
      let offsetTop = 0;
      while (element) {
        offsetTop += element.offsetTop;
        element = element.offsetParent;
      }
      return offsetTop;
    },

    scrollToElement(element) {
      window.scrollTo({
        top: this.getOffsetTop(element) - 100, // adjust the scroll position by 100px
        behavior: "smooth",
      });
    },

    sendEvent() {
      this.$emit("handle_submit", this.prop);
    },

    clear_fields() {
      this.prop = {
        type: null,
        comments: null,
        purchase_price: 0,
        purchase_date: null,
        tenure: null,
        rental_property: false,
        mls_listing: false,
        mls_listing_number: null,

        // prop_rent_info
        prop_rent_info: null,

        // customers
        customers: [],

        // address data
        address: {
          line1: null,
          line2: null,
          street_number: null,
          street_name: null,
          street_type: null,
          street_direction: null,
          unit: null,
          postal_code: null,
          city: null,
          province: null,
          years: 0,
          months: 0,

          legal_description: null,
          lot: null,
          block: null,
          plan: null,
        },

        // property cost data
        prop_cost: {
          include_in_tds: false,
          property_taxes: 0,
          tax_year: null,
          paid_by: null,
          tax_arrears: false,
          tax_arrears_amount: 0,
          tax_arrears_notes: [],
          maintenance_fee: 0,
          maintenance_arrears: false,
          maintenance_arrears_amount: 0,
          maintenance_arrears_notes: [],
          maintenance_condo_allocation_percentage: null,
          fee_include_heat: false,
          heating: 0,
          others: 0,
          hydro: 0,
          insurance: 0,
          management: 0,
          repairs: 0,
          interest_fee: 0,
          rental_income_offset: null,
        },

        // property style data
        prop_style: {
          property_style: null,
          property_type: null,
          garage_size: null,
          garage_type: null,
          living_space: null,
          construction_type: null,
          structure_age: null,
          number_of_units: null,
          lot_size: null,
          lot_measurement_type: null,
          heat: null,
          water_info: null,
          sewage_info: null,
          environmental_hazard: false,
          environmental_hazard_notes: null,
          environmental_hazard_comments: null,
        },

        // prop_val data
        prop_vals: [],

        // prop_mort data
        prop_morts: [],
      };
    },

    handle_copy_address(data) {
      (this.prop.address.line1 = data.line1),
        (this.prop.address.line2 = data.line2),
        (this.prop.address.street_number = data.street_number),
        (this.prop.address.street_name = data.street_name),
        (this.prop.address.street_type = data.street_type),
        (this.prop.address.street_direction = data.street_direction),
        (this.prop.address.unit = data.unit),
        (this.prop.address.postal_code = data.postal_code),
        (this.prop.address.city = data.city),
        (this.prop.address.province = data.province),
        (this.show_addresses = false);
    },

    handle_rental_property_info() {
      if (this.prop.rental_property) {
        this.prop.prop_rent_info = {
          monthly_income: 0,
          rental_income_options: null,
          rental_income_option_percent: null,
          rental_income_offset: null,
          gross_income_percent: null,
        };
      }

      if (!this.prop.rental_property) {
        this.prop.prop_rent_info = null;
      }
    },

    handle_save_customer(new_customer) {
      let new_order = 0;

      // console.log("new_customer", new_customer);

      if (this.prop.customers.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.prop.customers.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      new_customer.order = new_order;

      this.prop.customers.push(new_customer);

      this.read_customer_suggestions();
      this.is_adding_customer = false;
    },

    handle_unassociate_customer() {
      if (window.confirm("Are you sure you want to unassociate this?")) {
        this.selected_customers.forEach((item) => {
          this.prop.customers.splice(this.prop.customers.indexOf(item), 1);
        });

        this.selected_customers = [];
        this.read_customer_suggestions();
      } else {
        console.log("unassociating canceled");
        this.selected_customers = [];
      }
    },

    handle_submit_valuation(value) {
      if (this.is_adding_valuation) {
        this.handle_save_valuation(value);
      }

      if (this.is_updating_valuation) {
        this.handle_update_valuation(value);
      }
    },

    handle_save_valuation(new_prop_val) {
      let new_order = 0;
      if (this.prop.prop_vals.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.prop.prop_vals.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      new_prop_val.order = new_order;

      this.prop.prop_vals.push(new_prop_val);

      this.is_adding_valuation = false;
      this.is_updating_valuation = false;
    },

    handle_update_valuation(new_prop_val) {
      this.prop.prop_vals[
        this.prop.prop_vals.findIndex((x) => x.order === new_prop_val.order)
      ] = new_prop_val;

      this.is_adding_valuation = false;
      this.is_updating_valuation = false;
    },

    handle_updating_valuation(value) {
      if (this.is_adding_valuation) {
        alert(
          "You are currently adding a valuation. Please finish adding it first before updating an existing property valuation."
        );
        return;
      } else {
        this.is_updating_valuation = true;

        this.new_prop_val = value;
      }
    },

    handle_delete_valuation() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_valuations.forEach((item) => {
          this.prop.prop_vals.splice(this.prop.prop_vals.indexOf(item), 1);
        });

        this.selected_valuations = [];
      } else {
        console.log("deleting valuation canceled");
        this.selected_valuations = [];
      }
    },

    handle_save_mortgage(new_prop_mort) {
      let new_order = 0;
      if (this.prop.prop_morts.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.prop.prop_morts.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      new_prop_mort.order = new_order;

      this.prop.prop_morts.push(new_prop_mort);

      this.is_adding_mortgage = false;
      this.is_updating_mortgage = false;
    },

    handle_update_mortgage(new_prop_mort) {
      console.log("new_prop_mort", new_prop_mort);
      this.prop.prop_morts[
        this.prop.prop_morts.findIndex((x) => x.order === new_prop_mort.order)
      ] = new_prop_mort;

      this.is_adding_mortgage = false;
      this.is_updating_mortgage = false;
    },

    handle_to_update_mortgage(value) {
      if (this.is_adding_mortgage) {
        alert(
          "You are currently adding a mortgage. Please finish adding it first before updating an existing mortgage."
        );
        return;
      } else {
        this.is_updating_mortgage = true;

        this.new_prop_mort = value;
      }
    },

    handle_delete_mortgage() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_mortgages.forEach((item) => {
          this.prop.prop_morts.splice(this.prop.prop_morts.indexOf(item), 1);
        });

        this.selected_mortgages = [];
      } else {
        console.log("deleting existing mortgage canceled");
        this.selected_mortgages = [];
      }
    },

    // property cost methods

    handle_save_tax_arrears_note(data) {
      let new_order = 0;
      if (this.prop.prop_cost.tax_arrears_notes.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.prop.prop_cost.tax_arrears_notes.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      data.order = new_order;

      this.prop.prop_cost.tax_arrears_notes.push(data);

      this.new_tax_arrears_note = { note: "" };
      this.adding_tax_arrears_note = false;
    },

    handle_update_tax_arrears_note(data) {
      const index = this.prop.prop_cost.tax_arrears_notes.findIndex(
        (el) => el.order === data.order
      );

      this.prop.prop_cost.tax_arrears_notes[index].note = data.note;

      this.new_tax_arrears_note = { note: "" };
      this.tax_arrears_index = null;
      this.updating_tax_arrears_note = false;
    },

    handle_updating_tax_arrears_note(data) {
      if (this.adding_tax_arrears_note) {
        alert(
          "You are currently adding tax arrears note. Please finish adding it first before updating."
        );
        return;
      } else {
        this.updating_tax_arrears_note = true;

        this.new_tax_arrears_note = data;
      }
    },

    handle_delete_tax_arrears_note() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_tax_arrears_notes.forEach((item) => {
          this.prop.prop_cost.tax_arrears_notes.splice(
            this.prop.prop_cost.tax_arrears_notes.indexOf(item),
            1
          );
        });

        this.selected_tax_arrears_notes = [];
      } else {
        console.log("deleting tax arrears note canceled");
        this.selected_tax_arrears_notes = [];
      }
    },

    handle_save_maintenance_arrears_note(data) {
      let new_order = 0;
      if (this.prop.prop_cost.maintenance_arrears_notes.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.prop.prop_cost.maintenance_arrears_notes.map(
            (item) => item.order
          )
        );
        new_order = highest_order + 1;
      }

      data.order = new_order;

      this.prop.prop_cost.maintenance_arrears_notes.push(data);

      this.new_maintenance_arrears_note = { note: "" };
      this.adding_maintenance_arrears_note = false;
    },

    handle_update_maintenance_arrears_note(data) {
      const index = this.prop.prop_cost.maintenance_arrears_notes.findIndex(
        (el) => el.order === data.order
      );

      this.prop.prop_cost.maintenance_arrears_notes[index].note = data.note;

      this.new_maintenance_arrears_note = { note: "" };
      this.updating_maintenance_arrears_note = false;
    },

    handle_updating_maintenance_arrears_note(data) {
      if (this.adding_maintenance_arrears_note) {
        alert(
          "You are currently adding maintenance arrears note. Please finish adding it first before updating."
        );
        return;
      } else {
        this.updating_maintenance_arrears_note = true;

        this.new_maintenance_arrears_note = data;
      }
    },

    handle_delete_maintenance_arrears_note() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_maintenance_arrears_notes.forEach((item) => {
          this.prop.prop_cost.maintenance_arrears_notes.splice(
            this.prop.prop_cost.maintenance_arrears_notes.indexOf(item),
            1
          );
        });

        this.selected_maintenance_arrears_notes = [];
      } else {
        console.log("deleting maintenance arrears note canceled");
        this.selected_maintenance_arrears_notes = [];
      }
    },

    formatCurrency(amount) {
      let currencyValue = 0;

      if (amount) {
        amount = amount.toString();

        // Remove commas and dollar signs
        let unformattedValue = amount.replace(/[$,]/g, "");

        // Parse the input value as a number
        currencyValue = parseInt(unformattedValue) || 0;

        // Format the number with commas and a dollar sign
        let formatted_amount = currencyValue
          .toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
          .replace(".00", "");

        return formatted_amount;
      } else {
        return 0;
      }
    },

    handle_cancel_btn() {
      this.$emit("handle_cancel");
    },

    async read_property() {
      try {
        if (this.$route.query.property_id) {
          const response = await API().get(
            `api/internal-admin/property/read_property?prop_id=${this.$route.query.property_id}`
          );

          const prop_data = response.data;

          console.log("property_data", response.data);

          if (response.status === 200) {
            this.set_prop_data(prop_data);
          }
        }

        if (this.property_data) {
          this.set_prop_data(this.property_data);
        }
      } catch (error) {
        console.log(error);
      }
    },

    set_prop_data(prop_data) {
      if (prop_data) {
        delete prop_data.createdAt;
        delete prop_data.updatedAt;
        delete prop_data.prop_cost.createdAt;
        delete prop_data.prop_cost.updatedAt;
        delete prop_data.prop_style.createdAt;
        delete prop_data.prop_style.updatedAt;

        const prop_vals = prop_data.prop_vals;
        const prop_morts = prop_data.prop_morts;
        const tax_arrears_notes = prop_data.prop_cost.tax_arrears_notes;
        const maintenance_arrears_notes =
          prop_data.prop_cost.maintenance_arrears_notes;
        const prop_custs = prop_data.customers;
        prop_data.prop_vals = [];
        prop_data.prop_morts = [];
        prop_data.prop_cost.tax_arrears_notes = [];
        prop_data.prop_cost.maintenance_arrears_notes = [];
        prop_data.customers = [];

        prop_data.purchase_price =
          prop_data.purchase_price !== 0
            ? this.formatCurrency(prop_data.purchase_price)
            : 0;

        if (prop_data.rental_property) {
          delete prop_data.prop_rent_info.createdAt;
          delete prop_data.prop_rent_info.updatedAt;

          prop_data.prop_rent_info.monthly_income =
            prop_data.prop_rent_info.monthly_income !== 0
              ? this.formatCurrency(prop_data.prop_rent_info.monthly_income)
              : 0;
        }

        prop_data.prop_cost.property_taxes =
          prop_data.prop_cost.property_taxes !== 0
            ? this.formatCurrency(prop_data.prop_cost.property_taxes)
            : 0;

        prop_data.prop_cost.tax_arrears_amount =
          prop_data.prop_cost.tax_arrears_amount !== 0
            ? this.formatCurrency(prop_data.prop_cost.tax_arrears_amount)
            : 0;

        prop_data.prop_cost.maintenance_fee =
          prop_data.prop_cost.maintenance_fee !== 0
            ? this.formatCurrency(prop_data.prop_cost.maintenance_fee)
            : 0;

        prop_data.prop_cost.maintenance_arrears_amount =
          prop_data.prop_cost.maintenance_arrears_amount !== 0
            ? this.formatCurrency(
                prop_data.prop_cost.maintenance_arrears_amount
              )
            : 0;

        prop_data.prop_cost.heating =
          prop_data.prop_cost.heating !== 0
            ? this.formatCurrency(prop_data.prop_cost.heating)
            : 0;

        prop_data.prop_cost.others =
          prop_data.prop_cost.others !== 0
            ? this.formatCurrency(prop_data.prop_cost.others)
            : 0;

        prop_data.prop_cost.hydro =
          prop_data.prop_cost.hydro !== 0
            ? this.formatCurrency(prop_data.prop_cost.hydro)
            : 0;

        prop_data.prop_cost.insurance =
          prop_data.prop_cost.insurance !== 0
            ? this.formatCurrency(prop_data.prop_cost.insurance)
            : 0;

        prop_data.prop_cost.management =
          prop_data.prop_cost.management !== 0
            ? this.formatCurrency(prop_data.prop_cost.management)
            : 0;

        prop_data.prop_cost.repairs =
          prop_data.prop_cost.repairs !== 0
            ? this.formatCurrency(prop_data.prop_cost.repairs)
            : 0;

        prop_data.prop_cost.interest_fee =
          prop_data.prop_cost.interest_fee !== 0
            ? this.formatCurrency(prop_data.prop_cost.interest_fee)
            : 0;

        this.prop = prop_data;

        if (prop_vals) {
          prop_vals.forEach((item) => {
            delete item.createdAt;
            delete item.updatedAt;
            item.value = item.value !== 0 ? this.formatCurrency(item.value) : 0;
            this.handle_save_valuation(item);
          });
        }

        if (prop_morts) {
          prop_morts.forEach((item) => {
            delete item.createdAt;
            delete item.updatedAt;
            item.balance_remaining =
              item.balance_remaining !== 0
                ? this.formatCurrency(item.balance_remaining)
                : 0;
            item.payment_amount =
              item.payment_amount !== 0
                ? this.formatCurrency(item.payment_amount)
                : 0;
            item.original_amount =
              item.original_amount !== 0
                ? this.formatCurrency(item.original_amount)
                : 0;

            this.handle_save_mortgage(item);
          });
        }

        if (prop_custs) {
          prop_custs.forEach((item) => {
            delete item.createdAt;
            delete item.updatedAt;
            this.handle_save_customer(item);
          });
        }

        if (tax_arrears_notes) {
          tax_arrears_notes.forEach((item) => {
            delete item.createdAt;
            delete item.updatedAt;
            this.handle_save_tax_arrears_note(item);
          });
        }

        if (maintenance_arrears_notes) {
          maintenance_arrears_notes.forEach((item) => {
            delete item.createdAt;
            delete item.updatedAt;
            this.handle_save_maintenance_arrears_note(item);
          });
        }
      }
    },

    updating_from_update_customer(data) {
      this.set_prop_data(data);
    },

    async read_all_customer() {
      try {
        const response = await API().get(
          `api/internal-admin/customer/read_all_customers`
        );

        this.unassociated_customers = response.data;

        if (response) {
          for (const item of this.unassociated_customers) {
            if (this.prop.customers.some((el) => el.id === item.id)) {
              console.log("already associated current customer");
            } else {
              if (item.id === Number(this.$route.query.customer_id)) {
                this.handle_save_customer(item);
              }
            }
          }

          if (this.prop.customers.length > 0) {
            this.read_customer_suggestions();
          }
        }
        // associate the current customer to prop.customers
      } catch (error) {
        console.log(error);
      }
    },

    async read_customer_suggestions() {
      try {
        if (this.prop.customers.length > 0) {
          const response = await API().post(
            `api/internal-admin/property/customer_suggestions`,
            {
              customers: this.prop.customers,
            }
          );

          this.customer_suggestions = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    place_changed(place) {
      const addressComponents = place.address_components;

      const getAddressComponent = (type) => {
        const component = addressComponents.find((component) =>
          component.types.includes(type)
        );
        return component ? component.long_name : "";
      };

      // Assign basic address components
      this.prop.address.street_number = getAddressComponent("street_number");
      let streetName = getAddressComponent("route");
      this.prop.address.city = getAddressComponent("locality");
      this.prop.address.province = getAddressComponent(
        "administrative_area_level_1"
      );
      this.prop.address.postal_code = getAddressComponent("postal_code");
      this.prop.address.unit = getAddressComponent("subpremise");

      // Define street direction options in long text
      const streetDirectionOptions = [
        "North",
        "South",
        "East",
        "West",
        "Northeast",
        "Northwest",
        "Southeast",
        "Southwest",
      ];

      // Function to extract street direction and type
      const extractStreetDetails = (streetName) => {
        let direction = "";
        let type = "";

        // Check for direction at the start of the street name
        const directionPattern = new RegExp(
          `\\b(${streetDirectionOptions.join("|")})$`,
          "i"
        );
        const directionMatch = streetName.match(directionPattern);
        if (directionMatch) {
          direction = directionMatch[0];
          streetName = streetName.replace(directionPattern, "").trim();
        }

        // Check for type at the end of the street name
        const typePattern = new RegExp(
          `\\b(${this.street_type_options.join("|")})$`,
          "i"
        );
        const typeMatch = streetName.match(typePattern);
        if (typeMatch) {
          type = typeMatch[0];
          streetName = streetName.replace(typePattern, "").trim();
        }

        return { streetName, direction, type };
      };

      // Extract and assign street details
      const {
        streetName: finalStreetName,
        direction,
        type,
      } = extractStreetDetails(streetName);

      this.prop.address.street_name = finalStreetName;
      this.prop.address.street_type = type;

      // Assign direction from long text to short text
      const directionMap = {
        North: "N",
        South: "S",
        East: "E",
        West: "W",
        Northeast: "NE",
        Northwest: "NW",
        Southeast: "SE",
        Southwest: "SW",
      };

      this.prop.address.street_direction = directionMap[direction] || "";

      // Construct address_line1 and address_line2
      const addressLine1Components = [
        this.prop.address.street_number,
        this.prop.address.street_name,
        this.prop.address.street_direction,
        this.prop.address.street_type,
      ]
        .filter(Boolean)
        .join(" ");

      this.prop.address.line1 = addressLine1Components;

      this.prop.address.line2 = this.prop.address.unit
        ? `${this.prop.address.unit}`
        : "";
    },
  },

  computed: {
    associated_customer_addresses() {
      let addresses = [];

      if (this.prop.customers.length > 0) {
        this.prop.customers.forEach((customer) => {
          customer.addresses.forEach((address) => {
            console.log("address with id", address);
            address.customer_name = `${customer.first_name} ${customer.last_name}`;
            addresses.push(address);
          });
        });
      }

      if (this.existing_addresses) {
        this.existing_addresses.forEach((address) => {
          if (!address.id) {
            console.log("address without id", address);
            address.customer_name = `Current Customer`;
            addresses.push(address);
          }
        });
      }

      return addresses;
    },
  },

  filters: {
    ...filt,
  },

  mounted() {
    if (
      this.viewed_as === "update_from_customer" ||
      this.viewed_as === "update_from_deal" ||
      this.viewed_as === "update"
    ) {
      if (!this.adding_property) {
        this.read_property();
      }
      // console.log("existing address: ", this.existing_addresses);
    }

    this.read_all_customer();
  },
};
</script>

<style>
.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}

.currency-input .v-text-field__slot {
  padding-left: 48px;
}

.side-nav {
  background-color: #111 !important;
  color: white !important;
  top: 100px;
  position: sticky;
  position: -webkit-sticky;
}
</style>
