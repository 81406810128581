<template>
  <v-form v-model="is_valuation_valid">
    <v-card flat>
      <v-card-title>
        {{
          is_adding_valuation
            ? "Adding Property Valuation"
            : "Updating Property Valuation"
        }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-select
              :items="valuation_type_options"
              v-model="new_prop_val.type"
              label="Type"
              hide-details="auto"
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="new_prop_val.value"
              label="Value"
              hide-details="auto"
              outlined
              @blur="new_prop_val.value = formatCurrency(new_prop_val.value)"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="new_prop_val.source"
              label="Source (Appraiser / Realtor Name) optional"
              hide-details
              outlined
            />
          </v-col>

          <v-col>
            <v-menu
              v-model="date_menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="new_prop_val.date"
                  label="Date"
                  outlined
                  hide-details="auto"
                  v-bind="attrs"
                  v-on="on"
                  hint="YYYY-MM-DD format"
                  :rules="rule.date"
                  append-icon="mdi-calendar-blank"
                />
              </template>
              <v-date-picker
                no-title
                v-model="new_prop_val.date"
                @input="date_menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <v-switch
                  label="As Is / When Complete"
                  v-model="new_prop_val.as_is_when_complete"
                  @change="new_prop_val.as_is_value = 0"
                />
              </v-col>

              <v-col>
                <v-text-field
                  v-if="new_prop_val.as_is_when_complete"
                  v-model="new_prop_val.as_is_value"
                  label="As-Is Value"
                  hide-details="auto"
                  outlined
                  @blur="
                    new_prop_val.as_is_value = formatCurrency(
                      new_prop_val.as_is_value
                    )
                  "
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col>
            <v-switch
              label="Set as final value"
              v-model="new_prop_val.final_value"
              :disabled="
                !new_prop_val.final_value &&
                prop_vals.some((item) => item.final_value)
              "
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-textarea
              v-model="new_prop_val.note.note"
              label="Notes"
              hide-details
              auto-grow
              outlined
            />
          </v-col>

          <v-col></v-col>
        </v-row>
      </v-card-text>

      <v-divider class="mb-2" />

      <v-card-actions>
        <v-btn
          @click="handle_submit"
          color="primary"
          :disabled="!is_valuation_valid"
        >
          {{ is_adding_valuation ? "add" : "update" }}
        </v-btn>

        <v-btn @click="handle_cancel"> cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
export default {
  name: "AddValuation",

  props: {
    is_adding_valuation: {
      type: Boolean,
      required: true,
    },

    is_updating_valuation: {
      type: Boolean,
      required: true,
    },

    update_prop_val: {
      type: Object,
    },

    prop_vals: {
      type: Array,
    },
  },

  data: () => ({
    is_valuation_valid: false,
    date_menu: false,

    new_prop_val: {
      type: null,
      value: 0,
      source: null,
      date: null,
      final_value: false,
      archived: false,
      as_is_when_complete: false,
      as_is_value: 0,

      note: {
        note: null,
      },
    },

    // prop_val options
    valuation_type_options: [
      "Appraisal",
      "Estimated Value",
      "Comparative Market Analysis",
      "Tax Assessment",
      "Honest Door",
      "Purview",
      "Other",
    ],

    rule: {
      date: [
        (v) =>
          (v && /^\d{4}-\d{2}-\d{2}$/.test(v)) ||
          "Invalid date. Format should be YYYY-MM-DD",
      ],
    },
  }),

  methods: {
    handle_submit() {
      this.$emit("handle_submit", this.new_prop_val);
    },

    handle_cancel() {
      this.$emit("handle_cancel");
    },

    formatCurrency(amount) {
      let currencyValue = 0;

      if (amount !== 0) {
        amount = amount.toString();

        // Remove commas and dollar signs
        let unformattedValue = amount.replace(/[$,]/g, "");

        // Parse the input value as a number
        currencyValue = parseInt(unformattedValue) || 0;

        // Format the number with commas and a dollar sign
        let formatted_amount = currencyValue
          .toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
          .replace(".00", "");

        return formatted_amount;
      } else {
        return 0;
      }
    },
  },

  mounted() {
    if (this.is_updating_valuation) {
      this.new_prop_val = this.update_prop_val;
      this.new_prop_val.value = this.formatCurrency(this.new_prop_val.value);
      this.new_prop_val.as_is_value = this.formatCurrency(
        this.new_prop_val.as_is_value
      );
    }
  },
};
</script>
