<template>
  <v-card style="background-color: #111; border: 1px solid #333">
    <v-card-title class="text-body-2"> Add Existing Customers </v-card-title>

    <v-divider />

    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            class="mb-2"
            single-line
            hide-details
          />

          <v-data-table
            :headers="customers_header"
            :items="filteredUnassociatedCustomers"
            :search="search"
            :items-per-page="5"
            @click:row="handle_associate_customer"
          >
            <template v-slot:item.relations="{ item }">
              {{
                item.cust_rltns_1
                  .map((relation) => relation.cust_2.first_name)
                  .join(", ")
              }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider />

    <v-card-actions>
      <v-btn @click="$emit('close')"> cancel </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "AddRelationship",

  props: {
    type: {
      type: String,
    },

    associated_customers: {
      type: Array,
    },

    customer_suggestions: {
      type: Array,
    },

    unassociated_customers: {
      type: Array,
    },
  },

  data: () => ({
    search: "",

    customers_header: [
      { text: "First Name", value: "first_name" },
      { text: "Last Name", value: "last_name" },
      { text: "Email", value: "email" },
      { text: "Contact Number", value: "cell_phone_number" },
      { text: "Relations To", value: "relations" },
    ],
    // No need for the customers data property anymore
  }),

  computed: {
    filteredUnassociatedCustomers() {
      if (this.type === "associate_to_deal") {
        let associatedIds = this.associated_customers.map((int_cust) => {
          if (int_cust.customer) return int_cust.customer.id;
        });

        let suggestedIds = this.customer_suggestions.map(
          (suggested_cust) => suggested_cust.cust_2.id
        );

        let suggestedCustomers = this.unassociated_customers.filter(
          (customer) => suggestedIds.includes(customer.id)
        );

        let otherCustomers = this.unassociated_customers.filter(
          (customer) =>
            !associatedIds.includes(customer.id) &&
            !suggestedIds.includes(customer.id)
        );

        return suggestedCustomers.concat(otherCustomers);
      }

      if (this.type === "associate_to_property") {
        let associatedIds = this.associated_customers.map(
          (customer) => customer.id
        );

        let suggestedIds = this.customer_suggestions.map(
          (suggested_cust) => suggested_cust.cust_2.id
        );

        let suggestedCustomers = this.unassociated_customers.filter(
          (customer) => suggestedIds.includes(customer.id)
        );

        let otherCustomers = this.unassociated_customers.filter(
          (customer) =>
            !associatedIds.includes(customer.id) &&
            !suggestedIds.includes(customer.id)
        );

        return suggestedCustomers.concat(otherCustomers);
      }

      return [];
    },
  },

  methods: {
    handle_associate_customer(customer) {
      if (this.type === "associate_to_deal") {
        this.$emit("associate_customer", customer.id);
      }

      if (this.type === "associate_to_property") {
        this.$emit("handle_save_customer", customer);
      }
    },
  },
};
</script>
