<template>
  <v-form
    v-if="is_adding_mortgage || is_updating_mortgage"
    v-model="is_prop_mort_valid"
    class="mt-5"
  >
    <v-card>
      <v-card-title> Existing Mortgage Entry </v-card-title>

      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="new_prop_mort.position"
              type="number"
              label="Position"
              hide-details="auto"
              @blur="
                new_prop_mort.position === ''
                  ? (new_prop_mort.position = null)
                  : (new_prop_mort.position = new_prop_mort.position)
              "
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="new_prop_mort.lender"
              label="Lender Name"
              hide-details="auto"
              outlined
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="new_prop_mort.balance_remaining"
              label="Balance Remaining"
              hide-details="auto"
              outlined
              @blur="
                new_prop_mort.balance_remaining = formatCurrency(
                  new_prop_mort.balance_remaining
                )
              "
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-textarea
              v-model="new_prop_mort.comments"
              label="Comments"
              auto-grow
              hide-details="auto"
              outlined
            />
          </v-col>

          <v-col>
            <v-menu
              v-model="maturity_date_menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="new_prop_mort.maturity_date"
                  label="Maturity Date"
                  outlined
                  hide-details="auto"
                  v-bind="attrs"
                  v-on="on"
                  :rules="rule.date"
                  hint="YYYY-MM-DD format"
                  append-icon="mdi-calendar-blank"
                />
              </template>

              <v-date-picker
                v-model="new_prop_mort.maturity_date"
                @input="maturity_date_menu = false"
                no-title
              />
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="new_prop_mort.payment_amount"
              label="Payment Amount"
              hide-details="auto"
              outlined
              @blur="
                new_prop_mort.payment_amount = formatCurrency(
                  new_prop_mort.payment_amount
                )
              "
            />
          </v-col>

          <v-col>
            <v-select
              v-model="new_prop_mort.frequency"
              label="Frequency"
              :items="frequency_options"
              hide-details="auto"
            />
          </v-col>

          <v-col>
            <v-select
              v-model="new_prop_mort.rate_type"
              label="Rate Type"
              :items="rate_type_options"
              hide-details="auto"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select
              v-model="new_prop_mort.loan_type"
              label="Loan Type"
              :items="loan_type_options"
              hide-details="auto"
            />
          </v-col>

          <v-col>
            <v-text-field
              v-model="new_prop_mort.interest_rate"
              label="Interest Rate"
              type="number"
              hide-details="auto"
              outlined
              @blur="
                new_prop_mort.interest_rate === ''
                  ? (new_prop_mort.interest_rate = null)
                  : (new_prop_mort.interest_rate = new_prop_mort.interest_rate)
              "
            />
          </v-col>

          <v-col>
            <v-select
              v-model="new_prop_mort.term_type"
              label="Term Type"
              :items="term_type_options"
              hide-details="auto"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="new_prop_mort.current_mortgage_number"
              label="Current Mortgage Number"
              hide-details="auto"
              outlined
            />
          </v-col>

          <v-col>
            <v-switch
              v-model="new_prop_mort.blended_amortization"
              label="Blended Amortization"
              dense
            />
          </v-col>

          <v-col>
            <v-switch v-model="new_prop_mort.insured" label="Insured" dense />
          </v-col>
        </v-row>

        <div v-if="new_prop_mort.insured">
          <v-divider class="mb-5" />

          <v-row>
            <v-col>
              <p class="grey--text">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo
                autem voluptate est nostrum nulla illo, repellendus adipisci
                doloribus recusandae error tenetur quo, asperiores natus
                suscipit repellat sed similique earum eveniet.
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                v-model="new_prop_mort.insurance_account_number"
                label="Insurance Account Number"
                hide-details="auto"
                outlined
              />
            </v-col>

            <v-col>
              <v-select
                v-model="new_prop_mort.insurer"
                label="Insurer"
                :items="insurer_options"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-textarea
                v-model="new_prop_mort.refi_additional_info"
                label="Refi Additional Info"
                auto-grow
                hide-details="auto"
                outlined
              />
            </v-col>
          </v-row>

          <v-divider class="mb-5" />
        </div>

        <v-row>
          <v-col>
            <v-text-field
              v-model="new_prop_mort.original_amount"
              label="Original Amount"
              hide-details="auto"
              outlined
              @blur="
                new_prop_mort.original_amount = formatCurrency(
                  new_prop_mort.original_amount
                )
              "
            />
          </v-col>

          <v-col>
            <v-switch
              label="In Arrears"
              v-model="new_prop_mort.arrears"
              dense
              @change="
                new_prop_mort.arrears_amount = 0;
                new_prop_mort.arrears_note = '';
              "
            />
          </v-col>
        </v-row>

        <v-row v-if="new_prop_mort.arrears">
          <v-col>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model="new_prop_mort.arrears_amount"
                  label="Amount"
                  outlined
                  hide-details="auto"
                  @change="
                    new_prop_mort.arrears_amount = formatCurrency(
                      new_prop_mort.arrears_amount
                    )
                  "
                />
              </v-col>
            </v-row>

            <v-row v-if="new_prop_mort.arrears_notes.length > 0">
              <v-col>
                <v-data-table
                  style="background-color: #111"
                  v-model="selected_arrears_notes"
                  :headers="note_header"
                  item-key="order"
                  :items="new_prop_mort.arrears_notes"
                  :items-per-page="5"
                  @click:row="handle_updating_arrears_note($event)"
                  show-select
                />

                <v-btn
                  v-if="selected_arrears_notes.length > 0"
                  class="mt-2"
                  color="error"
                  @click="handle_delete_arrears_note"
                >
                  delete
                </v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn
                  v-if="!adding_arrears_note && !updating_arrears_note"
                  @click="
                    adding_arrears_note = true;
                    updating_arrears_note = false;
                  "
                >
                  add note
                </v-btn>

                <v-card
                  flat
                  outlined
                  v-if="adding_arrears_note || updating_arrears_note"
                >
                  <v-card-title>
                    {{ adding_arrears_note ? "Adding" : "Updating" }}
                    Note
                  </v-card-title>

                  <v-card-text>
                    <v-textarea
                      v-model="new_arrears_note.note"
                      label="Enter Note"
                      auto-grow
                      outlined
                      hide-details="auto"
                    />
                  </v-card-text>

                  <v-divider />

                  <v-card-actions>
                    <v-btn
                      @click="
                        adding_arrears_note
                          ? handle_save_arrears_note(new_arrears_note)
                          : handle_update_arrears_note(new_arrears_note)
                      "
                      color="primary"
                      :disabled="!new_arrears_note.note.length > 0"
                    >
                      save
                    </v-btn>

                    <v-btn
                      class="ml-2"
                      @click="
                        adding_arrears_note = false;
                        updating_arrears_note = false;
                      "
                    >
                      cancel
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider class="mb-2" />

      <v-card-actions>
        <v-btn
          @click="handle_submit"
          color="primary"
          :disabled="!is_prop_mort_valid"
        >
          {{ is_adding_mortgage ? "add" : "update" }}
        </v-btn>

        <v-btn @click="handle_cancel"> cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
export default {
  props: {
    is_adding_mortgage: {
      type: Boolean,
    },

    is_updating_mortgage: {
      type: Boolean,
    },

    update_prop_mort: {
      type: Object,
    },

    prop_morts: {
      type: Array,
    },
  },

  data: () => ({
    maturity_date_menu: false,

    is_prop_mort_valid: false,

    adding_arrears_note: false,
    updating_arrears_note: false,
    new_arrears_note: {
      note: "",
    },

    selected_arrears_notes: [],

    note_header: [{ text: "Note", value: "note" }],

    // prop_mort options
    frequency_options: [
      "monthly",
      "semi-monthly",
      "biweekly",
      "accelerated-biweekly",
      "weekly",
      "accelerated-weekly",
    ],

    rate_type_options: [
      "fixed",
      "adjustable",
      "capped-variable",
      "variable",
      "buy-down",
    ],

    payoff_options: ["none", "prior-to-advance", "from-proceeds", "other"],
    loan_type_options: ["mortgage", "secured-loc"],
    term_type_options: ["closed", "open", "convertible"],
    insurer_options: ["cmhc", "genworth", "canada-guaranty"],

    new_prop_mort: {
      position: null,
      balance_remaining: 0,
      lender: null,
      maturity_date: null,
      comments: null,
      payoff: null,
      original_amount: 0,
      payment_amount: 0,
      frequency: null,
      rate_type: null,
      arrears: false,
      arrears_amount: 0,
      arrears_notes: [],
      loan_type: null,
      interest_rate: null,
      term_type: null,
      current_mortgage_number: null,
      blended_amortization: false,
      insured: false,
      insurance_account_number: null,
      insurer: null,
      refi_additional_info: null,
      archived: false,
    },

    rule: {
      date: [
        (v) =>
          (v && /^\d{4}-\d{2}-\d{2}$/.test(v)) ||
          "Invalid date. Format should be YYYY-MM-DD",
      ],
    },
  }),

  methods: {
    handle_save_arrears_note(data) {
      let new_order = 0;

      if (this.new_prop_mort.arrears_notes.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.new_prop_mort.arrears_notes.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      data.order = new_order;

      this.new_prop_mort.arrears_notes.push(data);

      this.new_arrears_note = {
        note: "",
      };

      this.adding_arrears_note = false;
    },

    handle_update_arrears_note(data) {
      const index = this.new_prop_mort.arrears_notes.findIndex(
        (el) => el.order === data.order
      );

      this.new_prop_mort.arrears_notes.splice(index, 1, data);

      this.new_arrears_note = {
        note: "",
      };

      this.updating_arrears_note = false;
    },

    handle_updating_arrears_note(item) {
      if (this.adding_arrears_note) {
        alert(
          "You are currently adding a note. Please finish adding it first before updating."
        );
        return;
      } else {
        this.updating_arrears_note = true;

        this.new_arrears_note = item;
      }
    },

    handle_delete_arrears_note() {
      if (window.confirm("Are you sure you want to delete this?")) {
        this.selected_arrears_notes.forEach((item) => {
          const index = this.new_prop_mort.arrears_notes.findIndex(
            (el) => el.order === item.order
          );

          this.new_prop_mort.arrears_notes.splice(index, 1);
        });

        this.selected_arrears_notes = [];
      } else {
        console.log("deleting arrears note canceled");
        this.selected_arrears_notes = [];
      }
    },

    handle_cancel() {
      this.$emit("handle_cancel");
    },

    handle_submit() {
      if (!this.is_updating_mortgage) {
        let new_position = 1;

        if (this.prop_morts.length > 0) {
          // get the heighest positon number
          const highest_position = Math.max.apply(
            Math,
            this.prop_morts.map((item) => item.position)
          );

          new_position = highest_position + 1;
        }

        if (new_position < Number(this.new_prop_mort.position)) {
          alert("Please enter mortgages in priority first.");
        }

        if (new_position > Number(this.new_prop_mort.position)) {
          alert(`This mortgage position already exist.`);
        }

        if (new_position === Number(this.new_prop_mort.position)) {
          this.$emit("handle_submit", this.new_prop_mort);
        }
      }

      if (this.is_updating_mortgage) {
        this.$emit("handle_submit", this.new_prop_mort);
      }
    },

    formatCurrency(amount) {
      let currencyValue = 0;

      if (amount !== 0) {
        amount = amount.toString();

        // Remove commas and dollar signs
        let unformattedValue = amount.replace(/[$,]/g, "");

        // Parse the input value as a number
        currencyValue = parseInt(unformattedValue) || 0;

        // Format the number with commas and a dollar sign
        let formatted_amount = currencyValue
          .toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
          .replace(".00", "");

        return formatted_amount;
      } else {
        return 0;
      }
    },
  },

  mounted() {
    if (this.is_updating_mortgage) {
      let new_arrears_notes = this.update_prop_mort.arrears_notes;
      this.update_prop_mort.arrears_notes = [];

      this.new_prop_mort = this.update_prop_mort;

      console.log("new arrears notes: ", new_arrears_notes);

      if (new_arrears_notes) {
        new_arrears_notes.forEach((item) => {
          this.handle_save_arrears_note(item);
        });
      }
    }

    // console.log("Prop Morts: ", this.prop_morts);
  },
};
</script>
